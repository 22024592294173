.filters {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  right: 0;
  left: 0;

  @media (max-width: 430px) {
    bottom: 10px;
  }
}
