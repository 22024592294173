.header h1 {
  position: absolute;
  top: -155px;
  width: 100%;
  font-size: 100px;
  font-weight: 100;
  text-align: center;
  color: rgba(175, 47, 47, 0.15);
  -webkit-text-rendering: optimizeLegibility;
  -moz-text-rendering: optimizeLegibility;
  text-rendering: optimizeLegibility; }

.header input {
  position: relative;
  margin: 0;
  width: 100%;
  font-size: 24px;
  font-family: inherit;
  font-weight: inherit;
  line-height: 1.4em;
  color: inherit;
  box-sizing: border-box;
  padding: 16px 16px 16px 60px;
  border: 0;
  box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.03);
  background: rgba(0, 0, 0, 0.003);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .header input::-webkit-input-placeholder {
    font-style: italic;
    font-weight: 300;
    color: #e6e6e6; }
  .header input::-moz-placeholder {
    font-style: italic;
    font-weight: 300;
    color: #e6e6e6; }
  .header input::input-placeholder {
    font-style: italic;
    font-weight: 300;
    color: #e6e6e6; }

@charset "UTF-8";
.todo {
  position: relative;
  font-size: 24px;
  border-bottom: 1px solid #ededed; }
  .todo__toggle {
    opacity: 0;
    text-align: center;
    width: 40px;
    /* auto, since non-WebKit browsers doesn't support input styling */
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    border: none;
    /* Mobile Safari */
    -webkit-appearance: none;
    -moz-appearance: none;
         appearance: none; }
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      .todo__toggle {
        background: none;
        height: 40px; } }
  .todo__label {
    word-break: break-all;
    padding: 15px 15px 15px 60px;
    display: block;
    line-height: 1.2;
    -webkit-transition: color 0.4s;
    transition: color 0.4s;
    /*
      Firefox requires `#` to be escaped - https://bugzilla.mozilla.org/show_bug.cgi?id=922433
      IE and Edge requires *everything* to be escaped to render, so we do that instead of just the `#` - https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/7157459/
    */
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%22-10%20-18%20100%20135%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2250%22%20fill%3D%22none%22%20stroke%3D%22%23ededed%22%20stroke-width%3D%223%22/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center left; }
  .todo__toggle:checked + .todo__label {
    background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2240%22%20height%3D%2240%22%20viewBox%3D%22-10%20-18%20100%20135%22%3E%3Ccircle%20cx%3D%2250%22%20cy%3D%2250%22%20r%3D%2250%22%20fill%3D%22none%22%20stroke%3D%22%23bddad5%22%20stroke-width%3D%223%22/%3E%3Cpath%20fill%3D%22%235dc2af%22%20d%3D%22M72%2025L42%2071%2027%2056l-4%204%2020%2020%2034-52z%22/%3E%3C/svg%3E"); }
  .todo__destroy {
    display: none;
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    width: 40px;
    height: 40px;
    margin: auto 0;
    font-size: 30px;
    color: #cc9a9a;
    margin-bottom: 11px;
    -webkit-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out; }
    .todo__destroy:hover {
      color: #af5b5e; }
    .todo__destroy:after {
      content: '\D7'; }
  .todo:hover .todo__destroy {
    display: block; }
  .todo__edit {
    display: none;
    position: relative;
    margin: 0 0 0 43px;
    width: calc(100% - 43px);
    font-size: 24px;
    font-family: inherit;
    font-weight: inherit;
    line-height: 1.4em;
    color: inherit;
    padding: 12px 16px;
    border: 1px solid #999;
    box-shadow: inset 0 -1px 5px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .todo_editing {
    border-bottom: none;
    padding: 0; }
  .todo_editing .todo__edit {
    display: block; }
  .todo_editing .todo__view {
    display: none; }
  .todo_completed .todo__label {
    color: #d9d9d9;
    text-decoration: line-through; }
  .todo_last {
    border-bottom: none; }
  .todo_last.todo_editing {
    margin-bottom: -1px; }

.todo-list {
  margin: 0;
  padding: 0;
  list-style: none; }

@charset "UTF-8";
.toggle-all {
  width: 1px;
  height: 1px;
  border: none;
  /* Mobile Safari */
  opacity: 0;
  position: absolute;
  right: 100%;
  bottom: 100%; }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .toggle-all {
      background: none; } }
  .toggle-all + label {
    width: 60px;
    height: 34px;
    font-size: 0;
    position: absolute;
    top: -52px;
    left: -13px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); }
  .toggle-all + label:before {
    content: '\276F';
    font-size: 22px;
    color: #e6e6e6;
    padding: 10px 27px 10px 27px; }
  .toggle-all:checked + label:before {
    color: #737373; }

.main {
  position: relative;
  z-index: 2;
  border-top: 1px solid #e6e6e6; }

.todo-count {
  float: left;
  text-align: left; }
  .todo-count strong {
    font-weight: 300; }

.filter-item {
  display: inline; }
  .filter-item a {
    color: inherit;
    margin: 3px;
    padding: 3px 7px;
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 3px; }
  .filter-item a:hover {
    border-color: rgba(175, 47, 47, 0.1); }
  .filter-item a.selected {
    border-color: rgba(175, 47, 47, 0.2); }

.filters {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  right: 0;
  left: 0; }
  @media (max-width: 430px) {
    .filters {
      bottom: 10px; } }

.footer {
  color: #777;
  padding: 10px 15px;
  height: 20px;
  text-align: center;
  border-top: 1px solid #e6e6e6; }
  @media (max-width: 430px) {
    .footer {
      height: 50px; } }
  .footer:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 50px;
    overflow: hidden;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), 0 8px 0 -3px #f6f6f6, 0 9px 1px -3px rgba(0, 0, 0, 0.2), 0 16px 0 -6px #f6f6f6, 0 17px 2px -6px rgba(0, 0, 0, 0.2); }
  .footer .clear-completed,
  .footer .clear-completed:active {
    float: right;
    position: relative;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer; }
  .footer .clear-completed:hover {
    text-decoration: underline; }

.todoapp {
  background: #fff;
  margin: 130px 0 40px 0;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1); }

.info {
  margin: 65px auto 0;
  color: #bfbfbf;
  font-size: 10px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-align: center; }
  .info p {
    line-height: 1; }
  .info a {
    color: inherit;
    text-decoration: none;
    font-weight: 400; }
    .info a:hover {
      text-decoration: underline; }

html,
body {
	margin: 0;
	padding: 0;
}

button {
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	font-size: 100%;
	vertical-align: baseline;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
	-webkit-appearance: none;
	-moz-appearance: none;
	     appearance: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font: 14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
	line-height: 1.4em;
	background: #f5f5f5;
	color: #4d4d4d;
	min-width: 230px;
	max-width: 550px;
	margin: 0 auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: 300;
}

:focus {
	outline: 0;
}

.hidden {
	display: none;
}

