.filter-item {
  display: inline;

  & a {
    color: inherit;
    margin: 3px;
    padding: 3px 7px;
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 3px;
  }

  & a:hover {
    border-color: rgba(175, 47, 47, 0.1);
  }

  & a.selected {
    border-color: rgba(175, 47, 47, 0.2);
  }
}
