.header {
  h1 {
    position: absolute;
    top: -155px;
    width: 100%;
    font-size: 100px;
    font-weight: 100;
    text-align: center;
    color: rgba(175, 47, 47, 0.15);
    -webkit-text-rendering: optimizeLegibility;
    -moz-text-rendering: optimizeLegibility;
    text-rendering: optimizeLegibility;
  }
  input {
    position: relative;
    margin: 0;
    width: 100%;
    font-size: 24px;
    font-family: inherit;
    font-weight: inherit;
    line-height: 1.4em;
    color: inherit;
    box-sizing: border-box;
    padding: 16px 16px 16px 60px;
    border: 0;
    box-shadow: inset 0 -2px 1px rgba(0,0,0,0.03);
    background: rgba(0, 0, 0, 0.003);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::-webkit-input-placeholder {
      font-style: italic;
      font-weight: 300;
      color: #e6e6e6;
    }
    &::-moz-placeholder {
      font-style: italic;
      font-weight: 300;
      color: #e6e6e6;
    }
    &::input-placeholder {
      font-style: italic;
      font-weight: 300;
      color: #e6e6e6;
    }
  }
}
